<template>
  <v-dialog v-model="dialog" persistent max-width="300">
    <v-card>
      <div class="primary white--text">
        <v-card-title>
          ส่งออกรายงาน - {{reportOption.name}}
          <v-btn icon class="ml-auto white--text" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </div>

      <v-card-actions>
        <v-row class="ma-0 pa-0">
          <v-col cols="12">รูปแบบการส่งออกข้อมูล</v-col>
          <v-col cols="12">
            <v-radio-group v-model="file" class="ma-0 ml-4" hide-details>
              <v-radio v-for="item in exportOption"
                  :key="item.label"
                  :value="item.value">
                <template v-slot:label>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                  <span class="black--text ml-3">{{ item.label }}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn :disabled="!file" color="success" :href="getReport()" target="_blank">ดำเนินการ</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    reportOption: Object
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dialog = newVal
      }
    }
  },
  data: () => ({
    dialog: true,
    exportOption: [
      { label: 'HTML (preview)', value: 'html', icon: 'mdi-file-search-outline', color: 'blue' },
      { label: 'PDF', value: 'pdf', icon: 'mdi-file-pdf-box', color: '#EA4C3A' },
      { label: 'XLSX (MS Excel)', value: 'xlsx', icon: 'mdi-file-excel-box', color: '#08743B' }
    ],
    file: null
  }),
  methods: {
    close () {
      this.file = null
      this.$emit('close')
    },
    getReport () {
      return `${process.env.VUE_APP_REPORT}/Reports/M_BMMS_reports/${
        this.reportOption.value}.${
        this.file}?bridge_id=${
        this.$route.query.id}`
    }
  }
}
</script>

<style lang="scss" scoped></style>
