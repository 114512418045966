<template>
  <div>
    <v-menu v-model="menuDate" :close-on-content-click="false" :disabled="disabled" transition="scale-transition" offset-y min-width="auto">
      <template #activator="{ on, attrs }">
        <v-text-field :value="dateText" ref="inp" append-icon="mdi-calendar" :background-color="backgroundColor" :disabled="disabled" :rules="rules" readonly outlined dense :hide-details="hideDetails"
                      v-bind="attrs" v-on="on" @click:append="menuDate=true"/>
      </template>
      <v-date-picker v-if="!readonly" v-model="date" :max="max" locale="th" color="primary" no-title scrollable/>
    </v-menu>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  props: {
    value: String,
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: new Array(0)
    },
    max: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.date = newVal
    },
    date (newVal, oldVal) {
      if (newVal) {
        this.menuDate = false
        this.$emit('input', newVal)
      }
    }
  },
  data: () => ({
    menuDate: false,
    date: ''
  }),
  mounted () {
    this.date = this.value
  },
  computed: {
    dateText () {
      if (!this.date || this.date === '') {
        return ''
      }
      const dateObj = utils.stringToDate(this.date)
      return utils.dateToThai(dateObj)
    }
  }
}
</script>

<style></style>
