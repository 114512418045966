<template>
  <div class="fill-height">
    <div class="fill-height">
      <div v-show="!showMap" class="pa-4">
        <div class="d-none d-sm-flex">
          <v-btn
            class="ml-auto white--text primary"
            @click="openBridgeDetail({ name: 'SI&A', value: 'SI_A' })"
          >
            <v-icon left>mdi-export-variant</v-icon>
            รายงาน SI&amp;A
          </v-btn>
        </div>
        <InventoryDetailTab
          :class="$vuetify.breakpoint.smAndUp ? '' : 'mt-8'"
        />
      </div>
      <div v-if="showMap" class="ma-0 pa-0 fill-height">
        <longdo-map :zoom="14" :lastView="false" :location="marker.location">
          <longdo-map-marker
            :location="marker.location"
            :title="getHeaderName(bridgeDetail.info)"
            :detail="markerDetail(bridgeDetail.info)"
          />
        </longdo-map>
      </div>
    </div>
    <v-btn
      v-if="marker.location.lat"
      fab
      fixed
      bottom
      right
      class="mb-6 blue"
      @click="showMap = !showMap"
    >
      <v-icon v-if="showMap" color="white">mdi-menu</v-icon>
      <v-icon v-else color="white">mdi-map-outline</v-icon>
    </v-btn>

    <BridgeDetailExportDialog
      v-model="bridgeDetailDialog"
      :reportOption="reportOption"
      @close="bridgeDetailDialog = false"
    />
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
import BridgeDetailExportDialog from '../../components/dialog/BridgeDetailExportDialog'
import InventoryDetailTab from '../../components/Inventory/Detail/InventoryDetailTab.vue'
export default {
  components: {
    BridgeDetailExportDialog,
    InventoryDetailTab
  },

  data: () => ({
    getKm: utils.getKm,
    tab: null,
    color: '#C22D47',
    reportList: [
      { name: 'SI&A', value: 'SI_A' }
      // { name: 'S.R.', value: 'bridge_SR01' }
    ],
    bridgeDetailDialog: false,
    showMap: false,
    reportOption: {}
  }),
  methods: {
    openBridgeDetail (reportOption) {
      this.reportOption = reportOption
      this.bridgeDetailDialog = true
    },
    getHeaderName (info) {
      return `${info.name} ${info.direction ? `(${info.direction})` : ''}`
    },
    markerDetail (item) {
      console.log(item)
      return `
        <div class="v-application">
          <table style="font-size: 13px; table-layout: fixed;">
            <tr>
              <td style="padding: 1px 8px;">km</td>
              <td style="padding: 1px 8px;">${this.getKm(item.km)}</td>
            </tr>
          </table>
        </div>
      `
    }
  },
  computed: {
    bridgeDetail () {
      return this.$store.state.bridgeDetail.detail
    },
    marker () {
      const geom = String(this.bridgeDetail.info.geom)
        .replace('POINT', '')
        .replace('(', '')
        .replace(')', '')
      const lonlat = geom.split(' ')
      return {
        title: this.bridgeDetail.info.name,
        location: {
          lon: lonlat[0],
          lat: lonlat[1]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-color {
  color: #c22d47;
}
.background-table {
  background-color: #c22d47;
}
.left {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.split {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
